<template>
  <div>
    <div class="container">
      <!-- BEGIN: Error Page -->
      <div
          class="error-page flex flex-col lg:flex-row items-center justify-center h-screen text-center lg:text-left"
      >
        <div class="-intro-x lg:mr-20">
          <img
              alt=""
              class="h-48 lg:h-auto"
              :src="require(`@/assets/images/error-illustration.svg`)"
          />
        </div>
        <div class="text-white mt-10 lg:mt-0">
          <div class="intro-x text-6xl font-medium" style="color: black;">401</div>
          <div class="intro-x text-xl lg:text-3xl font-medium" style="color: black;">
            当前访问权限不足
          </div>
          <div class="intro-x text-lg mt-3" style="color: black;">
            请重新获取授权或联系管理员处理
          </div>
        </div>
      </div>
      <!-- END: Error Page -->
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    cash("body")
        .removeClass("app")
        .addClass("login");
  },
  methods:{
    onFanHui:  function(){
      this.$router.push('/Dashboard');
    }
  }
};
</script>
